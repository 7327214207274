// Copyright 2022-2024 - Hewlett Packard Enterprise Company
import { getGroups } from '../actions/Api';
import {
  AUTO_ADD_TAG_SEPARATOR_CHAR,
  FIRMWARE_CATEGORY,
  HOST_OS_TYPES,
  SETTING_CATEGORY_STORAGE,
  SETTING_CATEGORY_EXTERNAL_STORAGE,
  SETTING_CATEGORY_ILO_SECURITY,
  SERVER_GEN_TO_DISPLAY_GEN_MAP,
  SERVER_GEN_TO_GROUP_FW_SETTING_GEN_MAP,
  FIRMWARE_BATCH_UPDATE_TEMPLATE_URI,
  GROUP_PLATFORM_FAMILY_MAP,
  PLATFORM_EDGELINE_UPPER,
} from '../constants';
import { convertVolumeSizeGBToGiB } from './serverSettingHelpers';

export async function validateUniqueName({ id, name }) {
  const req = getGroups(
    0,
    1,
    null, // sort
    null, // search
    [`name eq '${name.replaceAll("'", "''")}'`],
  );

  const response = await req.catch(e => {
    // If the REST request fails, go ahead and
    // send the POST request.  The POST will fail
    // if the name is a duplicate.
    response.data = { count: 0 };
  });

  const result = { isValid: true };
  if (
    response?.data?.count &&
    response?.data?.items &&
    response.data.items[0].id !== id
  ) {
    result.isValid = false;
    result.errorMessage =
      'Another group has the same name.  Enter a unique name.';
  }

  return result;
}

export function validateAutoAddTagToggle({
  autoAddServerTag,
  autoAddServersToggle,
}) {
  if (
    autoAddServersToggle &&
    (!autoAddServerTag || Object.keys(autoAddServerTag).length === 0)
  ) {
    return {
      errorMessage:
        'A tag must be specified, or "Automate adding servers" disabled.',
      isValid: false,
    };
  }
  return { isValid: true };
}

export async function validateUniqueAutoAddTag({
  id,
  autoAddServerTag,
  autoAddServersToggle,
}) {
  const entries = (autoAddServerTag && Object.entries(autoAddServerTag)) || [];
  let response;

  // nothing configured, short circuit to valid
  if (entries.length === 0 || !autoAddServersToggle) return { isValid: true };

  // expecting only a single auto add server tag, so just looking at the first
  const [key, value] = entries[0];

  const autoAddTagString = `${key}${AUTO_ADD_TAG_SEPARATOR_CHAR}${value || ''}`;

  const req = getGroups(
    0,
    1,
    null, // sort
    null, // search
    [
      `autoAddServerTag_ eq '${autoAddTagString
        .toLocaleLowerCase()
        .replaceAll("'", "''")}'`,
    ],
  );

  response = await req.catch(e => {
    // If the REST request fails, go ahead and
    // send the POST request.  The POST will fail
    // if the auto add tag is a duplicate.
    response = { data: { count: 0 } };
  });

  const result = { isValid: true };
  if (
    response?.data?.count &&
    response?.data?.items &&
    response.data.items[0].id !== id
  ) {
    result.isValid = false;
    result.errorMessage =
      'Another group has the same tag configured.  Enter a unique tag.';
  }

  return result;
}

export async function validateAutoAdd(formValues) {
  const validations = [validateAutoAddTagToggle, validateUniqueAutoAddTag];

  // iterate all of the form level validators
  for (let i = 0; i < validations.length; i++) {
    // eslint-disable-next-line no-await-in-loop
    const response = await validations[i](formValues);

    if (!response.isValid) {
      return response;
    }
  }

  return { isValid: true };
}

export function hasScheduledFirmwareUpdate(group) {
  return (
    group.schedules_ &&
    group.schedules_.some(
      schedule =>
        schedule.operation.body.jobTemplateUri ===
          FIRMWARE_BATCH_UPDATE_TEMPLATE_URI &&
        schedule.nextStartAt &&
        schedule.operation?.body?.data?.devices?.length &&
        schedule.operation.body.data.devices.length > 0,
    )
  );
}

function isServerInSchedule(server, schedule) {
  return (
    schedule.operation.body.jobTemplateUri ===
      FIRMWARE_BATCH_UPDATE_TEMPLATE_URI &&
    schedule.nextStartAt &&
    schedule.operation?.body?.data?.devices &&
    schedule.operation.body.data.devices.some(
      deviceId => deviceId === server.id,
    )
  );
}

export function getServersScheduledForUpdate(group, servers) {
  const result = [];
  servers.forEach(server => {
    if (
      group.schedules_ &&
      group.schedules_.some(schedule => isServerInSchedule(server, schedule))
    ) {
      result.push(server);
    }
  });
  return result;
}

// using this assumes the design decision of a single server setting type per group holds
export function getSettingDataByCategory(group, category) {
  const upperCaseCategory = category?.toUpperCase();

  return group?.serverSettings_?.find(
    setting => setting.category?.toUpperCase() === upperCaseCategory,
  );
}

export function getGroupFirmwareServerSettings(group) {
  return getSettingDataByCategory(group, FIRMWARE_CATEGORY);
}

export function getSelectedServerGroupFirmwareSetting(
  selectedServer,
  group,
  edgelineFirmwareUpdateFlag,
) {
  let selectedServerSettings;

  const groupFirmwareServerSettings = getGroupFirmwareServerSettings(group);

  const serverGen = selectedServer?.serverGeneration;
  const platformFamily = selectedServer?.platformFamily;
  if (serverGen && platformFamily && groupFirmwareServerSettings) {
    const settingsName = groupFirmwareServerSettings.name;
    const gen = serverGen.replaceAll('_', '');
    const filterKey =
      edgelineFirmwareUpdateFlag && platformFamily === PLATFORM_EDGELINE_UPPER
        ? key => key.includes(PLATFORM_EDGELINE_UPPER)
        : key => gen === key;

    const selectedServerGenSettings = Object.keys(
      groupFirmwareServerSettings?.settings || {},
    )
      .filter(filterKey)
      .reduce((genSettings, key) => {
        genSettings[key] = groupFirmwareServerSettings?.settings?.[key];
        return genSettings;
      }, {});

    selectedServerSettings = {
      name: settingsName,
      settings: selectedServerGenSettings,
    };
  }

  return selectedServerSettings;
}

export function getGroupIloSettings(group) {
  return getSettingDataByCategory(group, SETTING_CATEGORY_ILO_SECURITY);
}

export function getGroupExternalStorageSettings(group) {
  return getSettingDataByCategory(group, SETTING_CATEGORY_EXTERNAL_STORAGE);
}

export function getGroupFirmwareBundleId(group, generation) {
  const setting = getGroupFirmwareServerSettings(group);
  return setting?.settings?.[generation]?.id;
}

export function getGroupFirmwareDisplayName(group, generation) {
  const setting = getGroupFirmwareServerSettings(group);
  return setting?.settings?.[generation]?.firmwareDisplayName_;
}

export const getGroupBaselineServerIssue = (server, serverSetting) => {
  let issue;
  // check if no servers settings present
  if (
    serverSetting?.settings &&
    Object.keys(serverSetting?.settings).length > 0
  ) {
    // get the baseline based on server generation
    const baseline =
      serverSetting?.settings?.[
        SERVER_GEN_TO_GROUP_FW_SETTING_GEN_MAP[server?.serverGeneration]
      ];

    // check if baseline is active
    if (baseline && !baseline.isActive_) {
      // eslint-disable-next-line max-len
      issue = [
        'baseline_update_wizard.bulk_group_preflight_content.err_group_fw_baseline_not_supported',
        {
          generation:
            SERVER_GEN_TO_DISPLAY_GEN_MAP[server.serverGeneration] ||
            server.serverGeneration,
        },
      ];
    } else if (!baseline) {
      // baseline is not available for server generation
      // eslint-disable-next-line max-len
      issue = [
        'baseline_update_wizard.bulk_group_preflight_content.err_group_fw_baseline_gen_not_set',
        {
          generation:
            SERVER_GEN_TO_DISPLAY_GEN_MAP[server.serverGeneration] ||
            server.serverGeneration,
        },
      ];
    }
  } else {
    issue = [
      'baseline_update_wizard.bulk_group_preflight_content.err_group_fw_setting_not_set',
    ];
  }
  return issue;
};

// Comparison function for sorting groups alphabetically by name
// Returns 0 if groups have the same name
// Returns < 0 if a comes before b alphabetically
// Returns > 0 if b comes before a alphabetically
export function compareGroups(a, b) {
  if (!a && !b) return 0;
  if (!a && b) return 1;
  if (a && !b) return -1;

  return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
}

export function getInternalStorageData(group, multiVolumeSupportFlag) {
  let raidType;
  let volumeSizeInGB;
  let volumes = [];
  let internalStorageSettingsName;

  if (multiVolumeSupportFlag) {
    group?.serverSettings_?.forEach(setting => {
      if (setting.category === SETTING_CATEGORY_STORAGE) {
        if (setting.settings.DEFAULT.raidType) {
          const storageName =
            group.serverPolicies?.onServerAdd?.storageVolumeName || '';
          const tempStorageObject = {
            id: null,
            name: storageName,
            raidType: setting.settings.DEFAULT.raidType,
            capacityInGB:
              setting.settings.DEFAULT.volumeSizeInGB === -1
                ? null
                : convertVolumeSizeGBToGiB(
                    setting.settings.DEFAULT.volumeSizeInGB,
                  ),
            driveCount: null,
            spareDriveCount: null,
            driveTechnology: null,
            ioPerfModeEnabled: null,
            readCachePolicy: null,
            writeCachePolicy: null,
          };
          volumes.push(tempStorageObject);
        } else {
          volumes = setting.settings.DEFAULT.volumes;
          group.serverPolicies?.onServerAdd?.storageVolumes?.length > 0 &&
            group.serverPolicies.onServerAdd.storageVolumes.forEach(
              storageVol => {
                volumes.forEach(storage => {
                  if (storageVol.id === storage.id) {
                    storage.name = storageVol.name;
                  }
                });
              },
            );
        }
        internalStorageSettingsName = setting.name;
      }
    });

    return {
      internalStorageSettingsName,
      volumes,
    };
  }

  group?.serverSettings_?.forEach(setting => {
    if (setting.category === SETTING_CATEGORY_STORAGE) {
      raidType = setting.settings.DEFAULT.raidType;
      volumeSizeInGB = setting.settings.DEFAULT.volumeSizeInGB;
    }
  });
  return {
    raidType,
    volumeSizeInGB,
  };
}

export function validateStorageVolumeName(value) {
  if (value.length > 64) {
    return {
      message: 'Volume label name must be 0-64 characters',
      status: 'error',
    };
  }
  return {
    message: '',
    status: 'info',
  };
}

const supportedModelVersions = {
  SRGen10: '5.32',
  SRGen10Plus: '03.01.14.062',
};

export function isSupportedModelAndVersion(storageInventory) {
  let isSupportedModelVersion = false;
  Object.values(storageInventory || {}).forEach(controller => {
    const {
      controllerModel: model,
      controllerFirmwareVersion: firmwareVersion,
    } = controller;
    if (!isSupportedModelVersion && model) {
      if (model.includes('Gen11') || model.includes('Gen12')) {
        isSupportedModelVersion = true;
      } else if (model.includes('Gen10+') && model.includes('SR')) {
        if (
          firmwareVersion &&
          firmwareVersion >= supportedModelVersions.SRGen10Plus
        ) {
          isSupportedModelVersion = true;
        }
      } else if (model.includes('Gen10') && model.includes('SR')) {
        if (
          firmwareVersion &&
          firmwareVersion >= supportedModelVersions.SRGen10
        ) {
          isSupportedModelVersion = true;
        }
      }
    }
  });

  return isSupportedModelVersion;
}

export function isAppliancePlatformFamily(platformFamily) {
  return (
    platformFamily === GROUP_PLATFORM_FAMILY_MAP.APPLIANCE_VM ||
    platformFamily === GROUP_PLATFORM_FAMILY_MAP.APPLIANCE_SYNERGY
  );
}

export function isGroupPlatformFamilyVm(platformFamily) {
  return platformFamily === GROUP_PLATFORM_FAMILY_MAP.APPLIANCE_VM;
}

export function isGroupPlatformFamilySynergy(platformFamily) {
  return platformFamily === GROUP_PLATFORM_FAMILY_MAP.APPLIANCE_SYNERGY;
}

export function getExternalStorageData(group) {
  let externalStorageHostOs;
  let name;
  group?.serverSettings_?.forEach(setting => {
    if (setting.category === SETTING_CATEGORY_EXTERNAL_STORAGE) {
      externalStorageHostOs = setting.settings.DEFAULT.externalStorageHostOs;
      name = setting.name;
    }
  });
  externalStorageHostOs = HOST_OS_TYPES[externalStorageHostOs];
  return {
    externalStorageHostOs,
    name,
  };
}
